import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import Box from "../box"
import Flex from "../flex"
import { H5 } from "../headings"
import Text from "../text"
import Wrapper from "../wrapper"

const Group = ({ children, label, width, multicolumn }) => (
  <Box as="section" width={width}>
    <H5 children={label} pb={2} />
    <Box
      pt={3}
      borderTop="1px solid"
      borderColor="border"
      css={css({ overflow: "hidden" })}
    >
      <Flex
        as="ul"
        flexWrap="wrap"
        css={css({
          mx: [-3, -4],
          my: -1,
          p: 0,
          listStyle: "none",
          "> *": {
            width: multicolumn ? "50%" : "100%",
            px: [3, 4],
            py: 1,
          },
        })}
      >
        {children}
      </Flex>
    </Box>
  </Box>
)

const NavItem = ({ label, to }) => (
  <Text as="li" fontSize={1}>
    <Link to={to} css={css({ textDecoration: "none" })}>
      {label}
    </Link>
  </Text>
)

const Upper = ({ footer_nav_menu }) => {
  return (
    <Wrapper as="section" py={[4, 5]}>
      <Flex
        flexWrap="wrap"
        css={css({
          m: [-3, -4],
          "> *": {
            p: [3, 4],
          },
        })}
      >
        {footer_nav_menu.document.data.body.map((item, i) => {
          let COLUMN_WIDTH = [1 / 2, 1 / 2, 1 / 4]
          let MULTI_COLUMN = false
          if (i === 0) {
            COLUMN_WIDTH = ["100%", null, 1 / 2]
            MULTI_COLUMN = true
          }

          return (
            <Group
              label={item.primary.group_label}
              width={COLUMN_WIDTH}
              multicolumn={MULTI_COLUMN}
              key={i}
            >
              {item.items.map((groupItem, i) => {
                return (
                  <NavItem
                    label={groupItem.group_item_label}
                    to={groupItem.group_item_link.url}
                    key={i}
                  />
                )
              })}
            </Group>
          )
        })}
      </Flex>
    </Wrapper>
  )
}

export default Upper
