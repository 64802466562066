import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import css from "@styled-system/css"
import { animateScroll } from "react-scroll"
import { rgba } from "polished"

import Box from "../box"
import Flex from "../flex"
import Text from "../text"
import RichText from "../richText"
import Wrapper from "../wrapper"

import SocialLinks from "./socialLinks"
import GoogleReviews from "./googleReviews"

function Details() {
  const { configuration } = useStaticQuery(
    graphql`
      {
        configuration: prismicConfiguration {
          data {
            footer_disclaimer {
              text
            }
            footer_secondary_buttons {
              label
              link {
                url
                link_type
              }
            }
            google_reviews_link {
              url
              link_type
            }
            google_reviews_rating
            google_reviews_number
            linkedin {
              url
            }
            instagram {
              url
            }
            facebook {
              url
            }
            youtube {
              url
            }
            twitter {
              url
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper as="section" py={4} borderTop="1px solid" borderColor="border">
      {configuration?.data?.footer_disclaimer?.text && (
        <Box width={["100%", "100%", 2 / 3]} mb={4}>
          <Text fontSize={0}>{configuration.data.footer_disclaimer.text}</Text>
        </Box>
      )}
      <Flex
        flexDirection={["column", null, "row"]}
        alignItems="center"
        width="100%"
      >
        <Box mr={[0, null, 2]} mb={[3, null, 0]}>
          <GoogleReviews
            link={configuration?.data?.google_reviews_link?.url ?? null}
            reviewCount={configuration?.data?.google_reviews_number ?? null}
            rating={configuration?.data?.google_reviews_rating ?? null}
          />
        </Box>
        <Box mr={[0, null, 2]} mb={[3, null, 0]}>
          <SocialLinks
            facebook={configuration?.data?.facebook ?? null}
            twitter={configuration?.data?.twitter ?? null}
            instagram={configuration?.data?.instagram ?? null}
            youtube={configuration?.data?.youtube ?? null}
            linkedin={configuration?.data?.linkedin ?? null}
          />
        </Box>
        <Flex
          flex={1}
          flexDirection={["column", null, "row"]}
          alignItems="center"
        >
          <Text mr={[0, 3]} fontSize={0} textAlign="center">
            © {new Date().getFullYear()} Partners Development Group
          </Text>
          <Box flex={1} mt={[3, null, 0]}>
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              flex={1}
              css={css({
                mx: -2,
                my: "-2px",
                "> *": {
                  px: 2,
                  py: "2px",
                },
              })}
            >
              {configuration?.data?.footer_secondary_buttons?.map((item, i) => {
                return (
                  <Text fontSize={0} key={i}>
                    <Link to={item?.link?.url}>{item?.label}</Link>
                  </Text>
                )
              })}
              <Text fontSize={0}>
                <a
                  href="https://builtbyfield.com/"
                  target="_blank"
                  rel="noopener"
                >
                  Design and code by Field
                </a>
              </Text>
              <Text
                ml={["initial", "auto"]}
                fontSize={0}
                textAlign="center"
                css={css({
                  width: ["100%", "initial"],
                })}
              >
                <span
                  css={css({
                    textDecoration: "none",
                    color: "inherit",
                    backgroundImage: theme =>
                      `linear-gradient(to bottom, ${rgba(
                        theme.colors.text,
                        0.25
                      )} 50%, ${rgba(theme.colors.text, 0.25)} 50%)`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "1px 1px",
                    backgroundPosition: "0 100%",
                    cursor: "pointer",
                  })}
                  role="button"
                  onClick={function() {
                    animateScroll.scrollToTop()
                  }}
                >
                  Scroll To Top
                </span>
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default Details
