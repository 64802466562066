import React from "react"
import css from "@styled-system/css"

import { AspectRatio } from "components"

function VirtualTour({ vr_link }) {
  return (
    <AspectRatio ratio="16:9">
      <iframe
        src={vr_link.url}
        title="vrTour"
        height="100%"
        width="100%"
        css={css({ border: 0 })}
      />
    </AspectRatio>
  )
}

export default VirtualTour
