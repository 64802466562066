import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"

import Actions from "./actions"
import Upper from "./upper"
import Details from "./details"

function Footer() {
  const { configuration } = useStaticQuery(
    graphql`
      {
        configuration: prismicConfiguration {
          data {
            footer_nav_menu {
              document {
                ... on PrismicNavMenu {
                  data {
                    label
                    link {
                      url
                      link_type
                    }
                    body {
                      __typename
                      ... on PrismicNavMenuBodyNavMenuGroup {
                        primary {
                          group_label
                        }
                        items {
                          group_item_label
                          group_item_link {
                            url
                            link_type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <footer
      css={css({
        width: "100%",
        overflow: "hidden",
      })}
    >
      <Actions />
      <Upper footer_nav_menu={configuration?.data?.footer_nav_menu ?? null} />
      <Details />
      <form
        name="generalContactForm"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="fullName" />
        <input type="email" name="email" />
        <input type="phone" name="phone" />
        <textarea type="text" name="message" />
      </form>
    </footer>
  )
}

export default Footer
