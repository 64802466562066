import React, { useState } from "react"
import css from "@styled-system/css"

import { RiExpandDiagonalSFill, RiVideoOnLine } from "react-icons/ri"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  H1,
  Image,
  RichText,
  Sidebar,
  Text,
  VirtualTour,
  Wrapper,
} from "components"

const ratio = "3:2"

const CustomImage = ({ item, order, onClick, ...rest }) => {
  // Determine if the item has a VR link
  let VR_LINK_PRESENT = item.vr_link && item.vr_link.url ? true : false

  return (
    <Box order={order}>
      <Box
        css={css({
          cursor: "pointer",
          ".hoverButton": {
            opacity: 0.3,
            transition: "opacity 250ms ease-out",
          },
          "&:hover": {
            ".hoverButton": { opacity: 1 },
          },
        })}
        onClick={onClick}
        {...rest}
      >
        <AspectRatio ratio={ratio}>
          <Image
            src={item?.image}
            alt={item?.image?.alt}
            css={css({ height: "100%" })}
          />
          <div
            css={css({
              position: "absolute",
              bottom: "8px",
              right: "12px",
            })}
            className="hoverButton"
          >
            {VR_LINK_PRESENT && (
              <RiVideoOnLine
                size={36}
                color="#3A3937"
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  overflow: "visible",
                  borderRadius: "calc(infinity * 1px)",
                }}
              />
            )}
            {!VR_LINK_PRESENT && (
              <RiExpandDiagonalSFill
                size={36}
                color="#3A3937"
                style={{
                  backgroundColor: "#fff",
                  padding: "8px",
                  overflow: "visible",
                  borderRadius: "calc(infinity * 1px)",
                }}
              />
            )}
          </div>
        </AspectRatio>
      </Box>
    </Box>
  )
}

const Row = ({ item1, item2, onClick1, onClick2 }) => (
  <Box>
    <Flex
      css={css({
        m: [-2, -3],
        "> *": {
          width: "50%",
          p: [2, 3],
        },
      })}
    >
      <CustomImage item={item1} onClick={onClick1} />
      <CustomImage item={item2} onClick={onClick2} />
    </Flex>
  </Box>
)

const ColumnOne = props => {
  const { images, onClick } = props

  return (
    <Box>
      <Flex
        flexDirection="column"
        css={css({
          m: [-2, -3],
          "> *": {
            width: "100%",
            p: [2, 3],
          },
        })}
      >
        {images?.length === 1 && (
          <CustomImage
            item={images && images[0]}
            onClick={() => {
              onClick(0)
            }}
          />
        )}
        {images[0] && images[1] && (
          <Row
            item1={images && images[0]}
            item2={images && images[1]}
            onClick1={() => {
              onClick(0)
            }}
            onClick2={() => {
              onClick(1)
            }}
          />
        )}
        {images[2] && (
          <CustomImage
            item={images && images[2]}
            onClick={() => {
              onClick(2)
            }}
          />
        )}
      </Flex>
    </Box>
  )
}

const ColumnTwo = ({ images, onClick }) => (
  <Box>
    <Flex
      flexDirection="column"
      css={css({
        m: [-2, -3],
        "> *": {
          width: "100%",
          p: [2, 3],
        },
      })}
    >
      {images[3] && (
        <CustomImage
          item={images && images[3]}
          order={[1, -1]}
          onClick={() => {
            onClick(3)
          }}
        />
      )}
      {images[4] && images[5] && (
        <Row
          item1={images && images[4]}
          item2={images && images[5]}
          onClick1={() => {
            onClick(4)
          }}
          onClick2={() => {
            onClick(5)
          }}
        />
      )}
    </Flex>
  </Box>
)

function Gallery(props) {
  const { id, images, allImages, description, descriptionTop } = props

  const [scrollPosition, setScrollPosition] = useState("")
  const [showAll, setShowAll] = useState(false)

  return (
    <>
      {descriptionTop && (
        <Wrapper
          as="section"
          id={descriptionTop && id}
          mt={[5, 6]}
          pt={[5, 6]}
          borderTop="1px solid"
          borderColor="border"
        >
          <H1 mb={[3, 4]}>Amenities</H1>
          <Box width="100%" maxWidth={800}>
            <RichText content={descriptionTop} fontSize={[1, 2, 3]} />
          </Box>
        </Wrapper>
      )}
      <Wrapper as="section" id={!descriptionTop && id} pt={[3, 4]}>
        <Box css={css({ overflow: "hidden" })}>
          <Flex
            flexWrap="wrap"
            css={css({
              m: [-2, -3],
              "> *": {
                width: ["100%", "50%"],
                p: [2, 3],
              },
            })}
          >
            <ColumnOne
              images={images}
              onClick={index => {
                setScrollPosition(id + "image" + index)
                setShowAll(true)
              }}
            />

            {images.length > 3 && (
              <ColumnTwo
                images={images}
                onClick={index => {
                  setScrollPosition(id + "image" + index)
                  setShowAll(true)
                }}
              />
            )}
          </Flex>
        </Box>
        {allImages.length > 6 && (
          <Box mt={4}>
            <Button
              children={`Show all photos`}
              variant="alt"
              onClick={() => {
                setScrollPosition("")
                setShowAll(true)
              }}
            />
          </Box>
        )}
      </Wrapper>
      <Sidebar
        id={id}
        scrollPosition={scrollPosition}
        showDialog={showAll}
        setShowDialog={setShowAll}
        side="right"
        maxWidth={1200}
        children={
          <>
            <Box
              pt={[48, 64]}
              pb={5}
              px={[3, 4]}
              css={css({ "> *:not(:last-of-type)": { mb: [3, 4] } })}
            >
              {allImages
                .sort((a, b) => {
                  if (a.featured === "Yes" && b.featured !== "Yes") {
                    return -1
                  }
                  if (b.featured === "Yes" && a.featured !== "Yes") {
                    return 1
                  }
                  return 0
                })
                .map((item, index) => {
                  let VR_LINK_PRESENT =
                    item.vr_link && item.vr_link.url ? true : false

                  return (
                    <Box id={id + "image" + index} key={id + "image" + index}>
                      <Box mx={[-3, 0]}>
                        {VR_LINK_PRESENT && (
                          <VirtualTour vr_link={item.vr_link} />
                        )}
                        {!VR_LINK_PRESENT && (
                          <Image src={item.image} alt={item.image.alt} />
                        )}
                      </Box>
                      <Text children={item.caption} mt={2} fontSize={[0, 1]} />
                    </Box>
                  )
                })}
            </Box>
          </>
        }
      />
      {description && description.html && description.html !== "<p></p>" && (
        <Wrapper pt={[5, 6]}>
          <Box width="100%" maxWidth={800}>
            <RichText content={description} fontSize={[1, 2, 3]} />
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default Gallery
